import React from "react";
import { Link } from "gatsby";

const Legal = () => {
  return (
    <div className="legal">
      <p className="legal-paragraph">
        © All Rights Reserved{" "}
        <span className="legal-paragraph--red">&nbsp;{new Date().getFullYear()}</span>
      </p>
      <div className="legal-sub">
        <Link to="/legal/impressum">Impressum</Link>
        <Link to="/legal/privacy-policy">Privacy Policy</Link>
      </div>
    </div>
  );
};

export default Legal;
