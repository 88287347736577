import React from "react";
import Navbar from "./Navbar";
import Legal from "./Legal";

const Layout = ({ children }) => {
  return (
    <>
      <Navbar />
      <main className="container container-mobile">{children}</main>
      <Legal />
    </>
  );
};

export default Layout;
