import React from "react";
import {Link} from 'gatsby';
import Logo from "../images/Logo.svg";
import { AnchorLink } from "gatsby-plugin-anchor-links";

const Navbar = () => {
  return (
    <div className="navbar" role="navigation" aria-label="main navigation">
      <div className="container navbar-flex">
        <div className="navbar-item">
          <a href="/">
            <img src={Logo} className="navbar-brand" alt="Logo" />
          </a>
        </div>
        <div className="navbar-menu">
          <AnchorLink to="/#about" className="navbar-item navbar-item-text">
            About
          </AnchorLink>
          {/* <Link to="tech" className="navbar-item navbar-item-text">Tech</Link> */}
          {/* <AnchorLink to="/" className="navbar-item navbar-item-text">
            Works
          </AnchorLink> */}
          <AnchorLink to="/#contact" className="navbar-item navbar-item-text">
            Contact
          </AnchorLink>

          <a
            href="https://www.fiverr.com/anesdev"
            className="btn btn-hire"
          >
            Hire Me
          </a>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
