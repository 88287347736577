import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Privacy Policy`}</h1>
    <p>{`Last updated: 02.01.2022`}</p>
    <p>{`The policy explains how I collect and treat any information you give me.`}</p>
    <h2>{`I respect your privacy`}</h2>
    <p>{`I respect and value your privacy as much as I do my own, I'm committed to making this website privacy-friendly and keeping your personal and business information safe.`}</p>
    <h2>{`How I collect information`}</h2>
    <ul>
      <li parentName="ul">{`I collect your email when you sign up for my newsletter`}</li>
      <li parentName="ul">{`I ask for your name and email and contact information when you use contact form`}</li>
    </ul>
    <h2>{`What information I hold`}</h2>
    <ul>
      <li parentName="ul">{`If you sign up for my newsletter, I only collect your email address and name`}</li>
      <li parentName="ul">{`If you contact me via contact form I hold your name, email and message`}</li>
    </ul>
    <h2>{`Where I store your information`}</h2>
    <ul>
      <li parentName="ul">{`If you sign up for my newsletter, I store your email address in Mailchimp.`}</li>
      <li parentName="ul">{`When you contact me by email or via contact form on my website, I don't store data anywhere.`}</li>
    </ul>
    <h2>{`What I use your information for`}</h2>
    <ul>
      <li parentName="ul">{`I use information to send you updates about new articles I've written.`}</li>
      <li parentName="ul">{`I will also use your information to send you invoices, statements or reminders.`}</li>
    </ul>
    <h2>{`Who’s responsible for your information`}</h2>
    <p>{`Anes Mulalic(me), is responsible for the security of your information.`}</p>
    <p>{`You can contact me by email `}<a parentName="p" {...{
        "href": "mailto:hello@anesmulalic.com"
      }}>{`hello@anesmulalic.com`}</a>{` or via contact form `}<a parentName="p" {...{
        "href": "http://www.anesmulalic.com/#contact"
      }}>{`www.anesmulalic.com/#contact`}</a>{` of you have any concerns about the information I store.`}</p>
    <h2>{`Who has access to information about you`}</h2>
    <p>{`When I store information in my own systems, only I have access.`}</p>
    <h2>{`The steps I take to keep your information private`}</h2>
    <p>{`Where I store your information in third-party services, access is restricted to myself.`}</p>
    <p>{`The computer I use is protected by passcode access.`}</p>
    <p>{`Same computer asks for authentication whenever it's started or after 5 minutes of inactivity.`}</p>
    <p>{`My mobile device is also protected by a pin and fingerprint.`}</p>
    <h2>{`How to complain`}</h2>
    <p>{`If you have any reason to complain about the ways I handle your privacy, please contact me by email at `}<a parentName="p" {...{
        "href": "mailto:hello@anesmulalic.com"
      }}>{`hello@anesmulalic.com`}</a>{` or via contact form on this website `}<a parentName="p" {...{
        "href": "http://www.anesmulalic.com/#contact"
      }}>{`www.anesmulalic.com/#contact`}</a>{`.`}</p>
    <h2>{`Changes to the policy`}</h2>
    <p>{`If I change the contents of this policy, those changes will become effective the moment I publish them on my website.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      